<template>
  <div class="detail-container">
    <div class="detail-content">
      <div v-if="detailInfo" class="form-row">
        <div class="form-group col-md-4">
          <label for="codeItem">코드 항목</label>
          <!-- <input v-model="detailInfo.codeItem" type="text" class="form-control"
                        id="codeItem" :disabled="!isEditMode"> -->
          <valid-input
            :vid="'코드 항목'"
            :classList="'form-control'"
            :inputType="'text'"
            :inputValue.sync="detailInfo.codeItem"
            :placeholder="$t('코드 항목')"
            :rules="rules.CODEITEM_RULE"
            :errorMessage="validMessage.CODEITEM_VALID_MESSAGE"
            :disabled="!isEditMode"
          ></valid-input>
        </div>
        <div class="form-group col-md-4">
          <label for="codeName">코드명</label>
          <!-- <input v-model="detailInfo.codeName" type="text" class="form-control" id="codeName"
                        :disabled="!isEditMode"> -->
          <valid-input
            :vid="'코드명'"
            :classList="'form-control'"
            :inputType="'text'"
            :inputValue.sync="detailInfo.codeName"
            :placeholder="$t('코드명')"
            :rules="rules.COMMON_NAME_RULE"
            :errorMessage="validMessage.COMMON_NAME_VALID_MESSAGE"
            :disabled="!isEditMode"
          ></valid-input>
        </div>
        <div class="form-group col-md-4">
          <label for="enabled">사용 여부</label>
          <select
            v-model="detailInfo.enabled"
            id="enabled"
            class="form-control"
            :disabled="!isEditMode"
          >
            <option value="">선택</option>
            <option
              v-for="code in $store.state.commonCodes.enabled"
              :key="code.value"
              :value="code.value"
            >
              {{ code.text }}
            </option>
          </select>
        </div>
      </div>
      <div v-if="detailInfo" class="form-row">
        <div class="form-group col-md-12">
          <label for="desc">설명</label>
          <!-- <textarea v-model="detailInfo.desc" type="text" class="form-control" id="desc"
                        :disabled="!isEditMode" rows="2" style="resize: none;"></textarea> -->
          <valid-textarea
            :inputType="'text'"
            :inputValue.sync="detailInfo.desc"
            :NonStatus="true"
            :rows="2"
            :classList="'desc-ctn form-control'"
            :rules="rules.COMMON_DESC_RULE"
            :disabled="!isEditMode"
          >
          </valid-textarea>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts.js";

// import backEndApi from "@src/api/backEndApi";

export default {
  props: ["data", "mode"],
  components: {},
  data() {
    return {
      detailInfo: null,
      rules,
      validMessage,
    };
  },
  watch: {
    data() {
      this.detailInfo = this.data;
    },
  },
  computed: {
    isEditMode() {
      return this.mode !== "view";
    },
  },
  created() {
    this.detailInfo = this.initData();
  },
  mounted() {},
  destroyed() {},
  methods: {
    initData() {
      return {
        codeItem: null,
        codeName: null,
        desc: null,
        enabled: "",
      };
    },
    setData(data) {
      if (data) {
        this.mode = "view";
        this.detailInfo = data;
      } else {
        this.mode = "edit";
        this.detailInfo = this.initData();
      }
    },
    getData() {
      return this.detailInfo;
    },
  },
};
</script>

<style scoped>
.detail-container {
  height: 100%;
  /* width: 100%; */
  border-radius: 8px;
  border: solid #eee 1px;
  /* background-color: white; */
  /* margin: 1rem; */
  font-size: 12px;
}

.detail-placeholder {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.detail-header {
  background-color: #fff;
  border-radius: 8px 8px 0px 0px;
  padding: 0.8rem 1rem;
  /* margin: 1rem 0rem; */
}

.detail-content {
  height: 88%;
  width: 100%;
  border-radius: 0px 0px 8px 8px;
  border: solid #eee 1px;
  padding: 1rem 1rem;
  /* overflow-y: auto; */
}

.detail-bottom {
  display: flex;
  justify-content: space-between;
}
</style>